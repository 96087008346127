import cookies from 'js-cookie';

function setCookie(value = '', name = null, id = null) {
  const key = id && name ? `${id}_${name}` : id || name;
  if (key) {
    cookies.set(key, value);
  }
}

function getCookie(name = null, id = null) {
  const key = id && name ? `${id}_${name}` : id || name;
  if (!key) {
    return false;
  }
  return cookies.get(key);
}

function populateInput() {
  document.querySelectorAll('input').forEach(element => {
    const value = getCookie(
      element.getAttribute('name'),
      element.getAttribute('id')
    );

    switch (element.getAttribute('type')) {
      case 'radio':
      case 'checkbox':
        if (value && value !== 'false') {
          element.setAttribute('checked', true);
        }
        element.addEventListener('change', event => {
          setCookie(
            event.target.checked,
            element.getAttribute('name'),
            element.getAttribute('id')
          );
        });
        break;

      case 'text':
      case 'email':
      default:
        if (value) {
          element.setAttribute('value', value);
        }
        element.addEventListener('input', event => {
          setCookie(
            event.target.value,
            element.getAttribute('name'),
            element.getAttribute('id')
          );
        });
    }
  });
}

function populateTextarea() {
  document.querySelectorAll('textarea').forEach(element => {
    const value = getCookie(
      element.getAttribute('name'),
      element.getAttribute('id')
    );
    if (value) {
      element.value = value;
    }
    element.addEventListener('input', e => {
      setCookie(
        e.target.value,
        element.getAttribute('name'),
        element.getAttribute('id')
      );
    });
  });
}

function prePopulateFormInput() {
  // Radio, Checkbox, Text
  populateInput();

  // Textarea
  populateTextarea();
}

prePopulateFormInput();
