// CSS
import '@groovepages/gp-page-ui-kit/dist/css/main.css';
import '@pages/assets/style/published/published.scss';
import 'animate.css/animate.min.css';

// JS
import '@groovepages/gp-page-ui-kit/dist/js/main.js';
import '@pages/builder/canvas/animate.js';
import '@pages/builder/canvas/delay.js';
import '@pages/builder/canvas/formInput.js';
import '@pages/builder/canvas/pluginHelper.js';
import '@pages/builder/canvas/popups.js';
import '@pages/builder/canvas/proxy-temp.js';
import './inpage_shared';
import './temp';

