window.gpHandleTabChange = currentTabLink => {
  if (window.inBuilder) window.dispatchEvent(new Event('resize'));
  const currentTab = document.querySelector(
    `[data-tab-link="${currentTabLink}"]`
  );
  if (!currentTab) return;
  currentTab.parentNode.querySelectorAll('[data-tab-link]').forEach(tab => {
    if (tab.getAttribute('data-tab-link') !== currentTabLink) {
      tab.querySelectorAll('[data-gp-vembed-id], groovevideo-widget iframe').forEach(iframe => {
        if (iframe.getAttribute('src')) {
          iframe.setAttribute('data-src', iframe.getAttribute('src'));
        }
        iframe.setAttribute('src', '');
      });
    } else {
      tab.querySelectorAll('[data-gp-vembed-id], groovevideo-widget iframe').forEach(iframe => {
        if (iframe.getAttribute('data-src'))
          iframe.setAttribute('src', iframe.getAttribute('data-src'));
      });
    }
  });
};
