function getDelayTime(node) {
  const element = node.classList;
  const secondPrepend = '000';
  for (const singleElement of element) {
    if (singleElement.includes('delay-show-')) {
      const second = singleElement.replace('delay-show-', '');
      return second + secondPrepend;
    }
  }
}

function showElement() {
  document.querySelectorAll('.hide-element').forEach(element => {
    const time = getDelayTime(element);
    if (time) {
      setTimeout(() => {
        if (element.classList.contains('hide-element')) {
          element.classList.remove('hide-element');
        }
      }, time);
    }
  });
}

showElement();