import cookies from 'js-cookie';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const popupId = urlParams?.get('popup');

const popupToShow = document.querySelector(`[data-gp-popup-id='${popupId}']`);
if (popupToShow?.classList?.contains('hidden-popup')) {
  popupToShow.classList.remove('hidden-popup');
}

const triggerEntryPopup = (popup, delay) => {
  delay = parseInt(delay, 10);
  if (Number.isNaN(delay)) delay = 0;
  setTimeout(() => {
    popup.classList.remove('hidden-popup');
  }, delay * 1000);
};

const triggerExitPopup = popup => {
  const trigger = e => {
    if (e.toElement || e.relatedTarget) return; // return if mouse is still inside page
    popup.classList.remove('hidden-popup');
    document
      .getElementById('page-container')
      .removeEventListener('mouseleave', trigger);
  };
  document
    .getElementById('page-container')
    .addEventListener('mouseleave', trigger, false);
};

const addAnimationClass = (popup, selectedAnimation) => {
  if (selectedAnimation) {
    popup.children[0].classList.add(selectedAnimation);
    popup.children[0].classList.add('animated');
  }
};

const observePopupsVisibility = () => {
  const popupsObserver = new MutationObserver(mutations => {
    mutations.forEach(mutationRecord => {
      if (mutationRecord.target.classList.contains('hidden-popup')) {
        mutationRecord.target
          .querySelectorAll('[data-gp-vembed-id]')
          .forEach(iframe => {
            if (iframe.getAttribute('src')) {
              iframe.setAttribute('data-src', iframe.getAttribute('src'));
            }
            iframe.setAttribute('src', '');
          });
      } else {
        mutationRecord.target
          .querySelectorAll('[data-gp-vembed-id]')
          .forEach(iframe => {
            if (iframe.getAttribute('data-src'))
              iframe.setAttribute('src', iframe.getAttribute('data-src'));
          });
      }
    });
  });
  document.querySelectorAll('[data-gp-popup-id]').forEach(popup => {
    popupsObserver.observe(popup, {
      attributes: true,
      attributeFilter: ['class']
    });
  });
};

const closePopupOnOverlayClick = (e, popup, leavingAnimation) => {
  // TODO: try using 'e.target === e.currentTarget' to check overlay click
  if (e.target.querySelectorAll('.data-gp-popup-container').length) {
    addAnimationClass(popup, leavingAnimation);
    setTimeout(() => {
      popup.children[0].classList.remove(leavingAnimation);
      popup.classList.add('hidden-popup');
    }, 1000);
  }
};

const closePopup = (popup, leavingAnimation) => {
  if (leavingAnimation) {
    addAnimationClass(popup, leavingAnimation);

    setTimeout(() => {
      if (popup.children[0].classList)
        popup.children[0].classList.remove(leavingAnimation);

      popup.classList.add('hidden-popup');
    }, 1000);
  } else popup.classList.add('hidden-popup');
};

const showPopup = (popup, popupData) => {
  const hasVisited = cookies.get('hasVisitedPopupPage');
  if (
    popupData.type === 'Entry' &&
    (popupData.behaviour === 'every' || !hasVisited)
  ) {
    triggerEntryPopup(popup, popupData.delay);
  }

  if (
    popupData.type === 'Exit' &&
    (popupData.behaviour === 'every' || !hasVisited)
  ) {
    triggerExitPopup(popup);
  }
};

const checkSitePopups = () => {
  const clearCheckSitePopups = setInterval(() => {
    if (window.site) {
      clearInterval(clearCheckSitePopups);
      window.site.popups.forEach(popupData => {
        let popup = document.querySelectorAll(
          `[data-gp-popup-id="${popupData.id}"]`
        );
        if (!popup.length) return;
        // eslint-disable-next-line prefer-destructuring
        popup = popup[0];

        if (popupData.appearingAnimation) {
          addAnimationClass(popup, popupData.appearingAnimation);
        }

        popup.addEventListener('click', e =>
          closePopupOnOverlayClick(e, popup, popupData.leavingAnimation)
        );

        Array.from(popup.querySelectorAll('.gp-close-popup')).forEach(
          popupClose => {
            popupClose.addEventListener('click', () =>
              closePopup(popup, popupData.leavingAnimation)
            );
          }
        );

        showPopup(popup, popupData);
      });
      cookies.set('hasVisitedPopupPage', true);
    }
  }, 250);
};

const checkQuickPopups = () => {
  const clearCheckQuickPopups = setInterval(() => {
    if (document.querySelectorAll('[data-gp-quick-popup-id]')) {
      clearInterval(clearCheckQuickPopups);
      const quickPopups = document.querySelectorAll('[data-gp-quick-popup-id]');
      quickPopups.forEach(popup => {
        const appearingAnimation = popup.getAttribute(
          'quick-popup-animation-in'
        );
        const leavingAnimation = popup.getAttribute(
          'quick-popup-animation-out'
        );
        if (appearingAnimation !== 'null')
          addAnimationClass(popup, appearingAnimation);
        popup.addEventListener('click', () => {
          if (leavingAnimation !== 'null') {
            addAnimationClass(popup, leavingAnimation);
            setTimeout(() => {
              popup.children[0].classList.remove(leavingAnimation);
              popup.classList.add('hidden-popup');
            }, 1000);
          } else popup.classList.add('hidden-popup');
        });
      });
    }
  }, 1000);
};

const closeGDPRcookieBars = () => {
  if (cookies.get('hasAcceptedGDPR')) {
    document
      .querySelectorAll('.gdpr-bar')
      .forEach(bar => bar.classList.add('hidden'));
  } else {
    document.querySelectorAll('.gdpr-close').forEach(closer => {
      closer.addEventListener('click', e => {
        e.target.closest('.gdpr-bar').classList.add('hidden');
        cookies.set('hasAcceptedGDPR', true);
      });
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  checkQuickPopups();

  checkSitePopups();

  observePopupsVisibility();

  closeGDPRcookieBars();
});
