function groovemailForm() {
  const query = new URLSearchParams(window.location.search);

  // Change Btn Text
  if (query?.get('formId')) {
    const form = document.querySelectorAll(
      `[data-gp-mail-form="${query?.get('formId')}"]`
    )?.[0];
    const submitBtn = form?.querySelector('[type="submit"]');
    if (submitBtn) submitBtn.textContent = 'Submitted!';
  }

  // Show Popup
  if (query?.get('popup'))
    document
      .querySelectorAll(`[data-gp-popup-id="${query.get('popup')}"]`)[0]
      .classList.remove('hidden-popup');
}

function isURL(str) {
  return str.includes('http://') || str.includes('https://');
}

function updateNavigation() {
  if (window.websiteurl) {
    document
      .querySelectorAll('.data-gp-navmenu-container a[href]')
      .forEach(item => {
        const hrefValue = item.getAttribute('href');
        let customUrl = '';

        if (!isURL(hrefValue)) {
          const separator =
            window.websiteurl.charAt(window.websiteurl.length - 1) === '/'
              ? ''
              : '/';

          if (hrefValue.includes('#')) customUrl = hrefValue;
          else customUrl = window.websiteurl + separator + hrefValue;

          item.href = customUrl;
        }
      });
  }
}

function addHiddenFields() {
  if (window.location.search.substr(1)) {
    const getParameters = window.location.search.substr(1).split('&');

    getParameters.forEach(param => {
      Array.from(document.getElementsByTagName('form')).forEach(form => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', param.split('=')[0]);
        input.setAttribute('value', param.split('=')[1]);
        form.appendChild(input);
      });
    });
  }
}

groovemailForm();
updateNavigation();
addHiddenFields();
