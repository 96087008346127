/*
    Add animation in published sites on
    - hover
    - on scroll
    - on repeat
    using animate.css (animated) class
*/

// https://betterprogramming.pub/how-to-restart-a-css-animation-with-javascript-and-what-is-the-dom-reflow-a86e8b6df00f

function animateOnHover() {
  document.querySelectorAll('.animate__onhover').forEach(element => {
    element.addEventListener('mouseover', () => {
      element.classList.add('animated');
      element.style.animation = 'none';
      const reflow = element.offsetHeight; // force browser reflow/repaint by accessing 'offsetHeight' to restart the animation
      element.style.animation = '';
      element.style.animationDuration =
        element.dataset.gpAnimationDuration || '';
    });
    element.addEventListener('mouseout', () => {
      element.classList.remove('animated');
    });
  });
}

function animateOnReveal() {
  const scrollObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animated');
          entry.target.style.animation = 'none';
          const reflow = entry.target.offsetHeight; // force browser reflow/repaint by accessing 'offsetHeight' to restart the animation
          entry.target.style.animation = '';
          entry.target.style.animationDuration =
            entry.target.dataset.gpAnimationDuration || '';
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0
    }
  );

  document.querySelectorAll('.animate__onreveal').forEach(element => {
    scrollObserver.observe(element);
  });
}

function animateOnRepeat() {
  document.querySelectorAll('.animate__repeat').forEach(element => {
    element.addEventListener('animationend', () => {
      element.style.animation = 'none';
      const reflow = element.offsetHeight; // force browser reflow/repaint by accessing 'offsetHeight' to restart the animation
      element.style.animation = '';
      element.style.animationDuration =
        element.dataset.gpAnimationDuration || '';
    });
  });
}

animateOnHover();
animateOnReveal();
animateOnRepeat();
